@import "../../ui/main.scss";

.image-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  background-color: transparent;

  .bg-dark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.75) !important;
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    max-width: 50%;
  }
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 7.5%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: $white;
    cursor: pointer;
    &:hover {
      color: rgba($white, 0.75);
    }
  }

  .right-arrow {
    position: absolute;
    top: 50%;
    right: 7.5%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: $white;
    cursor: pointer;
    &:hover {
      color: rgba($white, 0.75);
    }
  }
  .cross {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 10%;
    right: 5%;
    font-size: 5rem;
    color: $white;
    cursor: pointer;
    &:hover {
      color: rgba($white, 0.75);
    }
  }
}

@media only screen and (max-width: 1100px) {
  .image-view {
    img {
      max-width: 50%;
    }

    .left-arrow {
      left: 5%;
    }

    .right-arrow {
      right: 5%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .image-view {
    display: none;
    img {
      max-width: 20rem;
    }

    .left-arrow {
      left: 5%;
      font-size: 3rem;
    }

    .right-arrow {
      right: 5%;
      font-size: 3rem;
    }
  }
}
