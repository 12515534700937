@import "../../ui/main.scss";

.caprop-container {
  .banner {
    position: relative;
    .bg-dark {
      position: absolute;
      inset: 0;
      background-color: rgba($black, 0.75) !important;
    }
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }

  .caprop {
    padding: 3rem 10rem;
    padding-bottom: 5rem;

    .info {
      .section {
        p {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.CADiv1 {
  background-position: center;
  background-repeat: no-repeat;
  background-position: contain;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 600px) {
  .caprop-container {
    .caprop {
      padding: 3rem 5rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .caprop-container {
    .caprop {
      padding: 3rem 3rem;
    }
    .banner {
      position: relative;

      h1 {
        font-size: 2rem;
        width: fit-content;
      }
    }
  }
}
