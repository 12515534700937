@import "../../../ui/main.scss";
.newsletter-signup {
  width: 100%;
  padding: 3rem 10rem;

  label {
    padding-left: 0 !important;
  }

  input {
    padding: 0.5rem 0;
    padding-left: 1rem !important;
    outline: none;
    transition: all 0.3s;
    border: 1px solid rgba($color-primary-light, 0.5);
    border-radius: 5px;

    &:focus {
      border: 1px solid $color-secondary;
    }
    &::placeholder {
      color: rgba($light-grey, 0.75);
    }
  }
}

.NewsLetterFirstDiv {
  background-image: url("../../About_us/Aboutus_img/AbouUs_0_0_4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-position: contain;
  background-size: cover;
  box-shadow: 0px 2px 3px 2px;
  padding: 35px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
}
.NewsLetterBtn {
  display: flex;
  justify-content: center;
}

.NewsLetterSecondDiv {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1100px) {
  .newsletter-signup {
    padding: 3rem 5rem;
  }
}
@media screen and (max-width: 600px) {
  .newsletter-signup {
    padding: 3rem 3rem;
  }
}
