@import "../../../ui/main.scss";

.change-password {
  padding: 3rem 10rem;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;

  .reset-password-form {
    label {
      font-weight: bold;
      .red {
        color: red;
        font-weight: 100;
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .change-password {
    padding: 3rem 5rem;
  }
}

@media only screen and (max-width: 600px) {
  .change-password {
    padding: 3rem 3rem;
  }
}
