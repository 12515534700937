@import "../../../ui/main.scss";

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.sidebar-secondary-container {
  height: 100%;
  .sidebar-secondary-title {
    font-size: 2.5rem;
    margin-left: 2rem;
    color: $light-grey;
    font-weight: 400;
  }

  .page-wrapper .sidebar-wrapper,
  .sidebar-wrapper > a,
  .sidebar-wrapper .sidebar-dropdown > a:after,
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
  .sidebar-wrapper ul li a i,
  .page-wrapper .page-content,
  .sidebar-wrapper .sidebar-search input.search-menu,
  .sidebar-wrapper .sidebar-search .input-group-text,
  .sidebar-wrapper .sidebar-menu ul li a,
  #show-sidebar,
  #close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  /*----------------sidebar-wrapper----------------*/

  .page-wrapper {
    height: 100%;
  }
  .sidebar-wrapper {
    padding-top: 20px;
    width: 260px;
    height: 100%;

    top: 0;
    z-index: 999;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    a {
      text-decoration: none;
    }
  }

  /*-----------------------sidebar-search------------------------*/

  .sidebar-wrapper {
    .sidebar-search > div {
      padding: 10px 20px;
    }
  }

  /*----------------------sidebar-menu-------------------------*/

  .sidebar-wrapper {
    .sidebar-menu {
      padding-bottom: 10px;
      .header-menu span {
        font-weight: bold;
        font-size: 14px;
        padding: 15px 20px 5px 20px;
        display: inline-block;
      }
      ul li a {
        display: inline-block;
        width: 100%;
        text-decoration: none;
        position: relative;
        padding: 8px 30px 8px 20px;
        cursor: pointer;
        &:hover > i::before {
          display: inline-block;
          animation: swing ease-in-out 0.5s 1 alternate;
        }
        .span.label,
        span.badge {
          float: right;
          margin-top: 8px;
          margin-left: 5px;
        }
      }
      ul li a i {
        margin-right: 10px;
        font-size: 12px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }

      .sidebar-dropdown.active .sidebar-secondary:after {
        transform: rotate(180deg) !important;
        right: 17px;
      }
      // .sidebar-dropdown.active + .custom-active::after {
      //   color: $color-secondary !important;
      // }
      .sidebar-submenu {
        display: none;
      }
    }
  }

  /*--------------------------page-content-----------------------------*/

  .page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    padding-top: 20px;
    overflow-x: hidden;
  }

  .page-wrapper .page-content > div {
    padding: 20px 40px;
  }

  /*-----------------------------chiller-theme-------------------------------------------------*/

  .chiller-theme .sidebar-wrapper {
    // background: #31353d;
    background-color: $white;
    box-shadow: 5px 5px 5px rgba($black, 0.2);

    .sidebar-search,
    .sidebar-menu {
      // border-top: 1px solid #3a3f48;

      input.search-menu,
      .input-group-text {
        border-color: transparent;
        box-shadow: none;
      }
    }

    .sidebar-search input.search-menu,
    .sidebar-search .input-group-text,
    > a,
    .sidebar-menu ul li a,
    > a {
      color: $color-primary !important;

      &:hover {
        color: $color-secondary !important;
      }
    }

    .sidebar-menu ul li > a,
    .sidebar-menu .sidebar-dropdown.active > a,
    .chiller-theme .sidebar-wrapper > a {
      color: $white;
    }

    ul li:hover a i,
    .sidebar-dropdown .sidebar-submenu li a:hover:before,
    .sidebar-search input.search-menu:focus + span,
    .sidebar-menu .sidebar-dropdown.active a i {
      color: $color-secondary;
      text-shadow: 0px 0px 10px rgba($color-secondary, 0.5);
    }
  }
}
