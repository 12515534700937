@import "../main.scss";

.btn-primary {
  border-radius: 5px;
  padding: 0.75rem;
  border: 1px solid transparent;
  font-size: 1.2rem;
  min-width: 100px;
  text-transform: uppercase;
  background-color: $color-secondary;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  i {
    &:last-child {
      margin-left: 0;
    }
  }

  &:hover {
    background-color: transparent;
    border: 1px solid $color-secondary;
    color: $color-secondary;
  }
}

.btn-secondary {
  border-radius: 5px;
  padding: 0.75rem;
  border: 1px solid $color-primary;
  font-size: 1.2rem;
  min-width: 100px;
  text-transform: uppercase;
  color: $white;
  background-color: $color-primary;

  i {
    &:last-child {
      margin-left: 0;
    }
  }

  &:hover {
    border: 1px solid $dark-grey;
    background-color: $dark-grey;
    box-shadow: -1px -1px -1px -10px rgba($black, 0.6);
  }
  &:active,
  &:focus {
    background-color: $dark-grey !important;
  }
}

.btn-quantity {
  color: $white;
  background-color: $dark-grey;
  border-radius: 5px;
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5rem;

  .btn-small {
    padding: 0.3rem 1.2rem;
    user-select: none;
    background-color: $dark-grey;
    transition: all 0.2s;
    &:hover {
      background-color: $color-primary;
      cursor: pointer;
    }
  }
  .input-quantity {
    // padding: 0 1.5rem;
    background-color: $white;
    // padding-top: 5px;
    width: 4rem;
    border: none;
    outline: none;
    text-align: center;
  }
}
