@import "../../../ui/main.scss";

.main-nav {
  .menu-title {
    color: $color-primary;
    font-weight: 600;
    transition: all 0.3s;
    &:hover {
      color: $color-secondary;
    }
  }
  .menu-margin-bottom {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    a {
      &:hover {
        color: $color-secondary !important;
      }
    }
  }
}
.menu-secondary {
  a {
    &:hover {
      color: $color-secondary !important;
    }
  }
}
