@import "../../../ui/main.scss";
.featured-products-img {
  object-fit: contain;
  height: 150px;
  width: 300px;
}

.lazy-overlay {
  height: fit-content;
}

.img-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.blog-card {
  display: flex;
  gap: 20px;
  background-color: #fff;
  flex-direction: column;
  transition: all 0.3s ease;
  width: 350px;
  padding: 40px;
  height: 400px;

  &:hover {
    transform: scale(1.05);
    .read-more {
      color: $color-secondary !important;
    }
  }
}
