@import "../main.scss";

.input-search {
  background-color: #eeeeee;
  padding: 0.2rem;
  // border: 2px solid $color-secondary;
  max-width: 20rem;
  width: 100%;
  border-radius: 2px;
  display: flex;
  margin-left: 2rem;
  color: $white;
  transition: all 0.2s;

  i {
    font-size: 1.6rem;
    color: rgba($black, 0.75);
    padding: 0 0.5rem 0 0.5rem;
    transition: all 0.2s;
    cursor: pointer;
    display: inline-block;
  }
  &__input {
    background-color: transparent;
    border: none;
    color: $black;
    width: calc(100% - 2.6rem);
    &:focus {
      outline: none;
      border: none !important;
    }
    &::placeholder {
      color: rgba($black, 0.75);
    }
  }
}

.sidebar-search {
  border-radius: 5px;
  overflow: hidden;
}

.input-group-text {
  width: 4rem;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: $color-secondary;
  }
  i {
    margin: auto;
  }
}

.background-dark-grey {
  background-color: $dark-grey;
}

.backgrounnd-brown {
  background-color: rgba($color-secondary, 0.2);
}

.input-search-secondary {
  input {
    &:focus {
      border: 1px solid $color-secondary;
    }
  }
}
