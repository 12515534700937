@import "../../../ui/main.scss";

.review-order {
  padding: 3rem;
  .lead {
    margin-bottom: 0.4rem;
  }
  hr {
    margin-bottom: 1rem;
  }

  .bill-ship-container {
    display: flex;
    .bill {
      margin-right: 1.5rem;
    }
    .ship {
      margin-left: 1.5rem;
    }
  }
}

@media only screen and (max-width: 640px) {
  .review-order {
    .bill-ship-container {
      display: flex;
      flex-direction: column;
      .bill {
        margin-right: 0;
        margin-bottom: 3rem;
      }
      .ship {
        margin-left: 0;
      }
    }
  }
}
