@import "../main.scss";

.input-password-primary {
  width: 100%;
  .eye-icon-container {
    width: 4.5rem;
    background-color: $light-grey;
    border-radius: 5px;
    margin-left: 1rem;
    cursor: pointer;
    color: $white;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $color-secondary;
    }
  }
}
