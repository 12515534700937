$color-primary: #003e52;
$color-primary-light: #004d71;
$color-secondary: #ff671c;
$light-grey: #76777a;
$dark-grey: #3a3f48;
$black: #2d2a26;
$white: #fff;

$color-secondary-light: rgba($color-secondary, 0.2);
$grey-light: rgba($light-grey, 0.2);

html {
  scroll-behavior: smooth;
}

body {
  background-color: #eeeeee !important;
}
a {
  color: $color-secondary;
  &:hover,
  &:active,
  &:focus {
    color: $color-secondary;
  }
}

img {
  user-select: none;
}

.fs-m {
  font-size: 2.5rem;
}

.fs-s {
  font-size: 2rem;
}

.flex-1 {
  flex: 1;
  flex-wrap: wrap;
}

.display-4 {
  position: relative;
  display: inline-block;
  margin-bottom: 5rem !important;
  // font-weight: 400;
  &::before {
    content: "";
    position: absolute;
    bottom: -25%;
    left: 0.3%;
    height: 5px;
    width: 75%;
    max-width: 15rem;
    background-color: $color-secondary;
  }
}

.display-5 {
  position: relative;
  display: inline-block;
  margin-bottom: 5rem !important;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3rem;
  &::before {
    content: "";
    position: absolute;
    bottom: -30%;
    left: 0.3%;
    height: 2px;
    width: 50%;
    max-width: 7.5rem;
    background-color: $color-primary-light;
  }
}

.display-center-5 {
  position: relative;
  display: inline-block;
  margin-bottom: 5rem !important;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3rem;
  &::before {
    content: "";
    position: absolute;
    bottom: -30%;
    left: 0.3%;
    height: 2px;
    width: 50%;
    max-width: 7.5rem;
    background-color: $color-primary-light;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.invalid-data {
  font-size: 1rem;
  color: red;
  margin-bottom: 1rem;
}

.green {
  color: green;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.tc {
  text-align: center;
  margin: auto;
}

::selection {
  background: $color-secondary; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $color-secondary; /* Gecko Browsers */
}

// .active {
//   color: $color-secondary !important;
// }

@keyframes fadeIn {
  from {
    // transform: scale(0);
    opacity: 0;
  }
  to {
    // transform: scale(1);
    opacity: 1;
  }
}

@keyframes springScaleIn {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

// ::-webkit-scrollbar {
//   width: 7.5px;

//   /* Track */
//   &-track {
//     background-color: $white;
//   }

//   // &-button {
//   //   // height: 20rem;
//   //   background-color: $color-primary-light;
//   // }

//   /* Handle */
//   &-thumb {
//     background-color: $color-primary;

//     &:hover {
//       background-color: $color-secondary;
//     }
//   }
// }

#scroll-top.show {
  background-color: $dark-grey;
  z-index: 999999;
  &:hover {
    background-color: $light-grey;
    color: $white;
  }
}
