.checkout {
  padding: 5rem;
  min-height: 100vh;
}

@media only screen and (max-width: 640px) {
  .checkout {
    padding: 3rem;
  }
}
