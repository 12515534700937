@import "../../../ui/main.scss";
.tech-support {
  width: 100%;

  padding: 3rem 10rem;
  .info-container {
    h1 {
      margin-bottom: 3rem !important;
    }
    p {
      margin-bottom: 3rem;
    }
  }

  .formlabel {
    padding-left: 0 !important;
  }
  input {
    padding: 0.5rem 0;
    padding-left: 1rem !important;
    outline: none;
    transition: all 0.3s;
    border: 1px solid rgba($color-primary-light, 0.5);
    border-radius: 5px;

    &:focus {
      border: 1px solid $color-secondary;
    }
    &::placeholder {
      color: rgba($light-grey, 0.75);
    }
  }
  textarea {
    padding: 1rem;
    border: 1px solid rgba($color-primary-light, 0.5);
    outline: none;
    transition: all 0.3s;
    margin-bottom: 2rem;
    &:focus {
      border: 1px solid $color-secondary;
    }
    &::placeholder {
      color: rgba($light-grey, 0.75);
    }
  }

  select {
    border: 1px solid rgba($color-primary-light, 0.5);
    background-color: transparent;
    padding: 0.5rem;
    &:focus,
    &:active {
      border: 1px solid $color-secondary;
    }
  }
  .custom-control.custom-radio
    .custom-control-input:checked
    ~ .custom-control-label::before {
    border-color: $color-secondary;
  }
  .custom-control.custom-radio
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-color: $color-secondary;
  }

  .textarea-wrapper {
    position: relative;
    margin-bottom: 2rem;
    .invalid-data {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 90%;
    }
  }
}

.image {
  min-height: 100vh;
}

.bg-image {
  position: relative;
  width: 100%;
  height: auto;
  background-image: url("./Techsupport_images/main_2.jpg");
  background-size: cover;
  background-position: top;
  box-shadow: 1px 5px 5px 1px;
  margin-top: 30px;
}
.TechSecondDiv {
  margin-top: 40px;
  margin-bottom: 40px;
}
.form-group {
  position: relative;
  margin-bottom: 3rem;

  .invalid-data {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 110%;
  }
}
.formInput {
  border-radius: 5px;
}

.pb-cmnt-textarea {
  background-color: none;
  border-radius: 5px;
  height: 130px;
  width: 100%;
}
.comment_sec {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .tech-support {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 400px) {
  .tech-support {
    padding: 3rem 3rem;
  }
}
