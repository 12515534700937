@import "../../main.scss";
.loader-bar {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  .bar,
  .bar:before,
  .bar:after {
    background: $color-primary;
    animation: load 1s infinite ease-in-out;
    width: 1em;
    height: 3em;
    transition: all 0.3s;
  }
  .bar {
    color: $color-primary;
    transition: all 0.3s;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .bar:before,
  .bar:after {
    position: absolute;
    top: 0;
    content: "";
  }
  .bar:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }
  .bar:after {
    left: 1.5em;
  }
}
@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 4em;
  }
}
