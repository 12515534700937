@import "../../ui/main.scss";

.header {
  z-index: 9999999999 !important;
}
.footer {
  z-index: 9999;
  p,
  a {
    color: rgba($white, 0.5);
  }
  a {
    backface-visibility: hidden;
    transition: all 0.3s;
    &:hover {
      color: $color-secondary;
    }
  }
}
.header-left {
  a,
  i {
    &:hover {
      color: $color-secondary;
    }
  }
}

.top-menu span {
  &:hover {
    color: $color-secondary !important;
  }
}

.header-top a:hover,
.header-top a:focus {
  color: $color-secondary;
}

.header-right {
  a {
    // padding: 0.5rem 0;
    position: relative;
    // .fa-shopping-cart {
    //   font-size: 1.4rem !important;
    // }
    // .fa-user-circle {
    //   font-size: 1.7rem !important;
    // }
    height: fit-content;
    .cart-indicator {
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: $color-primary-light;
      border-radius: 50%;
      top: 0;
      right: 0;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      animation: scaleIn 0.3s ease-in-out;
    }
  }

  .social-icons {
    margin-right: 0 !important;
    a {
      transition: all 0.3s, color 0.1s;
      background-color: transparent;
      border-radius: 0;
      //   height: 2rem;
      padding: 0.5rem 0.5rem;
      width: fit-content;
      backface-visibility: hidden;
      i {
        transition: all 0.3s, color 0.1s;
        backface-visibility: hidden;
      }
      p {
        width: 0;
        color: $white;
        // visibility: hidden;
        opacity: 0;
        transition: all 0.3s, opacity 0.1s, color 0.1s;
        backface-visibility: hidden;
        display: none;
      }

      &:hover {
        &:nth-child(1) {
          background-color: #425f9c;
        }
        &:nth-child(2) {
          background-color: #1da1f2;
        }
        &:nth-child(3) {
          background-color: #0077b5;
        }
        &:nth-child(4) {
          background-color: $color-primary;
        }
        &:nth-child(5) {
          background-color: $color-secondary;
        }
        &:nth-child(6) {
          background-color: $black;
        }

        color: $white;
        z-index: 999;
        p {
          width: 100%;
          //   visibility: visible;
          display: inline-block;
          opacity: 1;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.header-bottom {
  background-color: $color-primary !important;
  a {
    flex-shrink: 0;
  }

  li {
    a {
      &::before {
        background-color: $color-secondary !important;
      }
    }
  }
  img {
    margin-right: 2rem;
  }
}

.footer {
  background-color: $color-primary;

  i {
    color: $color-secondary;
  }

  a {
    &:hover {
      color: $color-secondary;
    }
  }

  .footer-bottom {
    // img {
    //   width: 25rem;
    // }

    .footer-copyright {
      color: $white;
    }
  }
}

.mobile-menu-container {
  .btn i:first-child {
    margin-right: 0.2rem;
  }

  input {
    &:focus {
      border-color: $color-secondary !important;
    }
  }
  .mobile-nav {
    .mobile-menu li.open > a,
    .mobile-menu li.active > a {
      color: $color-secondary;
      span {
        &::after {
          color: $color-secondary;
        }
        &:focus,
        &:active {
          &::after {
            color: $color-secondary;
          }
        }
      }
    }

    .mobile-menu li a:hover,
    .mobile-menu li a:focus {
      color: $color-secondary;

      span {
        &::after {
          color: $color-secondary;
        }
        &:focus,
        &:active {
          &::after {
            color: $color-secondary;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .header-right {
    .social-icons {
      a {
        padding: 0 0;

        &:hover {
          &:nth-child(1) {
            background-color: unset;
          }
          &:nth-child(2) {
            background-color: unset;
          }
          &:nth-child(3) {
            background-color: unset;
          }
          &:nth-child(4) {
            background-color: unset;
          }
          &:nth-child(5) {
            background-color: unset;
          }
          &:nth-child(6) {
            background-color: unset;
          }
          color: unset;
          p {
            display: none;
          }
        }
      }
    }
  }
}
