@import "../../../ui//main.scss";

.shipments {
  padding: 3rem 10rem;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
}

.table {
  text-align: center;
  transform: rotateX(180deg);

  hr {
    border: 1px solid $dark-grey;
    width: 100%;
  }
  th {
    font-weight: bold !important;
    color: $black !important;
  }
  tr {
    position: relative;
    color: $light-grey !important;
    &::after {
      content: "";
      position: absolute;
      border: 1px solid rgba($light-grey, 0.5);
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }

  td {
    max-width: 20rem;
    min-width: 15rem;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .s-no {
    max-width: 5rem !important;
    min-width: 5rem !important;
  }
}

.scroll-x {
  overflow-x: scroll;
  scroll-behavior: smooth;
  transform: rotateX(180deg);
  margin-top: 1rem;

  &::-webkit-scrollbar {
    height: 10px;
    // display: none;

    /* Track */
    // &-track {
    // }

    // &-button {
    //   background-color: rgba($black, 0.5);
    // }

    /* Handle */
    &-thumb {
      background-color: $light-grey;

      &:hover {
        background-color: rgba($color-secondary, 0.75);
      }
    }
  }
}

.scroll-btn {
  transition: all 0.2s;
  font-size: 2rem;

  &:hover {
    color: $color-secondary;
    cursor: pointer;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  -o-user-select: none;
  user-select: none;
}

@media screen and (max-width: 1100px) {
  .shipments {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .shipments {
    padding: 3rem 3rem;
  }
}
