@import "../../ui/main.scss";

.cart-container {
  padding: 2rem 7.5rem;
  min-height: 100vh;

  .col-md-7 {
    padding: 0 2rem;
  }

  .billing-details {
    p {
      margin-bottom: 1rem;
    }
  }

  hr {
    margin: 1rem 0 1rem;
    border-color: rgba($light-grey, 0.1);
  }
  .lead {
    font-size: 2rem;
  }

  .flex-even {
    flex: 1;
    font-size: 1.3rem;
  }

  .no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
}

@media only screen and (max-width: 760px) {
  .cart-container {
    padding: 2rem 5rem;
  }
}

@media only screen and (max-width: 640px) {
  .cart-container {
    padding: 2rem 2rem;
  }
}
