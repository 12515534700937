@import "../../ui/main.scss";

.feedback-primary {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($black, 0.75);
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);

  .message {
    padding: 3rem;
    background-color: $color-primary;
    display: inline-block;
    margin-top: 10rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 2rem;
    height: 120px;
    // max-width: 20%;  // Ensure it doesn't exceed screen width
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    // height: 100%;

    i {
      margin-right: 1rem;
      color: inherit;
      font-size: inherit;
    }
    p {
      color: inherit;
      font-size: inherit;
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid $white;
  border-radius: 50%;
  border-top-color: $color-secondary;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
