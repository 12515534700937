@import "../../../ui/main.scss";
.faq-container {
  width: 100%;
  .banner {
    position: relative;

    .bg-dark {
      position: absolute;
      inset: 0;
      background-color: rgba($black, 0.75) !important;
    }
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      color: $white;
    }
  }
  .faq {
    min-height: 100vh;

    padding: 3rem 10rem;
  }
}

@media screen and (max-width: 600px) {
  .faq-container {
    .faq {
      padding: 3rem 5rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .faq-container {
    .banner {
      h1 {
        font-size: 2rem;
      }
    }
    .faq {
      padding: 3rem 3rem;
    }
  }
}
