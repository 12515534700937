@import "../../../ui/main.scss";
.nav-item.show {
  span {
    border-bottom: none !important;
    cursor: default !important;
  }
}

.tab-content {
  .form-group {
    input {
      &:focus {
        border: 1px solid $color-secondary;
      }
    }
  }
}
