@import "../main.scss";

.list-free-primary {
  animation: fadeIn 1s ease-out;
  padding: 2rem;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 5px 5px 5px rgba($black, 0.2);

  hr {
    margin: 0;
    padding: 0;
    background-color: rgba($black, 0.2);
  }
}

.background-brown {
  background-color: rgba($color-secondary, 0.1);
}

.background-grey {
  background-color: rgba($light-grey, 0.1);
}

.scroll-x {
  overflow-x: scroll;
}
