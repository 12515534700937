@import "../../ui//main.scss";

.products-container {
  padding: 3rem 10rem;
  min-height: 100vh;
  width: 100%;
}
.card-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category,
.subcategory {
  display: flex;
  width: 100%;
  // justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .products-container {
    padding: 3rem 3rem;
  }
}
