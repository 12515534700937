@import "../../../ui/main.scss";
.press-inquiry {
  width: 100%;
  padding: 3rem 10rem;

  .info-container {
  }

  label {
    padding-left: 0 !important;
  }
  input {
    padding: 0.5rem 0;
    padding-left: 1rem !important;
    outline: none;
    transition: all 0.3s;
    border: 1px solid rgba($color-primary-light, 0.5);
    border-radius: 5px;

    &:focus {
      border: 1px solid $color-secondary;
    }
    &::placeholder {
      color: rgba($light-grey, 0.75);
    }
  }
  textarea {
    padding: 1rem;
    outline: none;
    border: 1px solid rgba($color-primary-light, 0.5);
    width: 100% !important;
    transition: all 0.3s;
    &:focus {
      border: 1px solid $color-secondary;
    }
    &::placeholder {
      color: rgba($light-grey, 0.75);
    }
  }

  .textarea-wrapper {
    position: relative;
    margin-bottom: 2rem;
    .invalid-data {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 95%;
    }
  }
}

.check {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.check-input {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
.check-label {
  display: inline-block;
  margin-bottom: 0;
}

.PressFirstDiv {
  background-image: url("../../About_us/Aboutus_img/AbouUs_0_0_4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-position: contain;
  background-size: cover;
  box-shadow: 0px 2px 3px 2px;
  padding: 35px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
}
.PressSecondDiv {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1100px) {
  .press-inquiry {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .press-inquiry {
    padding: 3rem 3rem;
  }
  .PressMainContainer {
    margin-right: 15px;
    margin-left: 15px;
  }
}
