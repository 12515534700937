@import "../main.scss";

.card-secondary {
  border-radius: 10px;
  text-align: center;

  i {
    font-size: 5rem;
  }
  div {
    i,
    p {
      transition: all 0.2s;
    }
    &:hover {
      i,
      p {
        color: $dark-grey;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .card-secondary {
    margin-bottom: 2rem;
  }
}
