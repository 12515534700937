@import "../../ui/main.scss";

.newsletter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);

  .bg-dark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.75) !important;
  }
  .cross {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 10%;
    right: 5%;
    font-size: 5rem;
    color: $white;
    cursor: pointer;
    &:hover {
      color: rgba($white, 0.75);
    }
  }
  form {
    position: fixed;
    top: 15%;
    left: 20%;
    right: 20%;
    bottom: 20%;
    height: 435px;
    background-color: $white;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../forms/NewsLetter/newsbackground.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    h1 {
      text-align: center;
      display: block;
    }

    input {
      margin-bottom: 3rem;
      width: 75%;
      padding: 1rem;
      border: 1px solid rgba($color-primary-light, 0.5) !important;
      outline: none;
      transition: all 0.3s;
      border-radius: 5px;
      &:focus {
        border: 1px solid $color-secondary !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .newsletter {
    form {
      position: fixed;
      top: 20%;
      left: 5%;
      right: 5%;
      bottom: 20%;
      padding: 3rem;
    }
  }
}
