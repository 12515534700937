@import "../../ui/main.scss";
.partners {
  width: 100%;
  padding: 3rem 10rem;
  min-height: 100vh;
}
.map {
  position: relative !important;
  width: 100%;
}

.partners-section {
  width: 100%;
  .partners-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    place-items: center;
    .PartnerCard {
      width: 27.5rem;
      min-height: 15rem;
      position: relative;
      border-radius: 10px;
      margin-bottom: 3rem;
      background-color: $white;
      box-shadow: 5px 5px 5px rgba(black, 0.2);
      overflow: hidden;
      transform-style: preserve-3d;
      .front {
        background-color: $white;
      }
      .back {
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        h3 {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
        p {
          color: black;
          font-size: 1rem;
        }
        a {
          font-size: 1rem;
        }
      }

      &:hover {
        .front {
          transform: perspective(500px) rotateY(180deg);
        }
        .back {
          transform: perspective(500px) rotateY(360deg);
        }
      }

      .card-name,
      .card-city {
        // position: absolute;
        // left: 50%;
        // transform: translate(-50%, -50%);
        color: black;
        white-space: nowrap;
        text-align: center;
        font-weight: bold;
      }
      // .card-name {
      //   top: 40%;
      // }
      // .card-city {
      //   top: 60%;
      // }

      img {
        width: 100%;
        // height: 100%;
        object-fit: contain;
        margin: 1rem 0;
      }

      // .bg-dark {
      //   position: absolute;
      //   inset: 0;
      //   background-color: rgba(black, 0.5) !important;
      //   visibility: hidden;
      //   opacity: 0;
      // }

      // &:hover {
      //   .bg-dark {
      //     visibility: visible;
      //     opacity: 1;
      //   }
      // }
    }
  }
}

@media screen and (max-width: 1200px) {
  .partners-section {
    .partners-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 600px) {
  .partners {
    padding: 3rem 3rem;
  }
  .partners-section {
    width: 100%;
    .partners-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      place-items: center;
      .PartnerCard {
        // width: fit-content;
        margin-bottom: 3rem;
      }
    }
  }
}
