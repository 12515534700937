.imp-user {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .list-free-primary {
    padding: 10rem;
    text-align: center;
    h3 {
      font-size: 2.5rem;
    }
    p {
      margin-bottom: 2rem;
    }
  }
}
@media only screen and (max-width: 640px) {
  .imp-user {
    .list-free-primary {
      padding: 2rem;
    }
  }
}
