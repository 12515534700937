@import "../../../ui/main.scss";

.product-list {
  padding: 3rem 10rem;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1100px) {
  .product-list {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .product-list {
    padding: 3rem 3rem;
  }
}
