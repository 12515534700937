.media-description {
  padding: 3rem 10rem;
}

.Mediamain {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .media-description {
    padding: 3rem 3rem;
  }
}
