.carousel-primary {
  min-height: 30rem;
  width: 100%;
}
.carousel-tertiary {
  min-height: 20rem;
  width: 100%;
  max-height: 300px !important;
}

.carousel-button-group {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
  transform: translate(0, -50%);

  .arrow-left,
  .arrow-right {
    width: 5rem;
    height: 5rem;
    background-color: rgba(black, 0.5);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }
}

@media screen and (max-width: 640px) {
  .carousel-button-group {
    padding: 0 2rem;

    .arrow-left,
    .arrow-right {
      width: 4rem;
      height: 4rem;
    }
  }
}

.carousel-container3{
  height:300px
}