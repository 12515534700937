@import "../../../ui/main.scss";

.open-items {
  padding: 3rem 10rem;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  textarea {
    border-radius: 5px;
    padding: 0 1rem;
  }

  .comment {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.5);
    z-index: 999;
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    z-index: 9999999999;

    .comment-form {
      position: fixed;
      top: 20%;
      left: 20%;
      right: 20%;
      bottom: 20%;
      z-index: 1000;
      background-color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      textarea {
        background-color: transparent;
        padding: 1rem;
      }
    }
  }

  .comment-status {
    i {
      padding: 2rem;
      background-color: green;
      border-radius: 50%;
      color: $white;
      margin-bottom: 2rem;
    }

    p {
      color: green;
    }
  }
}

@media screen and (max-width: 1100px) {
  .open-items {
    padding: 3rem 5rem;
    .comment {
      .comment-form {
        top: 10%;
        left: 10%;
        right: 10%;
        bottom: 10%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .open-items {
    padding: 3rem 3rem;

    .comment {
      .comment-form {
        top: 5%;
        left: 5%;
        right: 5%;
        bottom: 5%;
      }
    }
  }
}
