@import "../main.scss";

.card-tertiary {
  // padding: 2rem;
  background-color: $white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba($black, 0.1);
  //   cursor: pointer;
  transition: all 0.3s;
  width: 25rem;

  .image-container {
    position: relative;
    img {
      width: 100%;
      height: 15rem;
    }
    .image-dark {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($black, 0.6);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: $white !important;
        text-transform: uppercase;
      }
    }
  }
  .text-container {
    padding: 1rem;
    p {
      font-size: 1.6rem;
      text-align: center;
    }
  }
  &:hover {
    .image-container {
      .image-dark {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
