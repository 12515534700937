.new-products {
  padding: 3rem 10rem;
}

@media screen and (max-width: 1100px) {
  .new-products {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .new-products {
    padding: 3rem 3rem;
  }
}
