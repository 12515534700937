@import "../../../ui/main.scss";

.Newsaccordion-primary-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.NewsTitle {
  color: $color-primary-light;
  &:hover {
    color: $color-primary-light;
    text-decoration: underline;
  }
}

/* Accordion styles */
.Newsaccordion-primary-tabs {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba($light-grey, 0.2);
  margin-bottom: 2rem;
}

.Newsaccordion-primary-tab {
  width: 100%;
  overflow: hidden;

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: rgba($dark-grey, 0.2);
    cursor: pointer;
    margin-bottom: 0;
    transition: all 0.2s;
    /* Icon */
    &:hover {
      background: rgba($color-secondary, 0.2);
    }
  }
  hr {
    margin: 0 0 0;
    display: none;
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $black;
    background: white;
    transition: all 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $color-secondary;
    cursor: pointer;
    &:hover {
      background: rgba($dark-grey, 0.2);
    }
  }
}

// :checked
.Newsaccordion-primary-input:checked {
  + .Newsaccordion-primary-tab-label {
    background: $white;
    &::after {
    }
  }
  ~ hr {
    display: block;
  }
  ~ .Newsaccordion-primary-tab-content {
    max-height: 100vh;
    width: 100% !important;
    padding: 1em;
    color: #777;
  }
}
