@import "../../ui/main.scss";

.about-us {
  width: 100%;
  padding: 3rem 10rem;

  .info {
    .section {
      margin-bottom: 3rem;
      p {
        margin-bottom: 2rem;
      }
      .display-5 {
        display: block;
        margin-bottom: 3rem !important;
      }
      span {
        display: block;
        font-weight: bold;
      }
    }
  }
}

.about-us-banner {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1100px) {
  .about-us {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .about-us {
    padding: 3rem 3rem;
  }
}
