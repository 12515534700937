.search-products {
  padding: 3rem 10rem;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1100px) {
  .search-products {
    padding: 3rem 5rem;
  }
}
@media screen and (max-width: 600px) {
  .search-products {
    padding: 3rem 3rem;
  }
}
