.orders {
  padding: 3rem 10rem;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .orders {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .orders {
    padding: 3rem 3rem;
  }
}
