@import "../../ui/main.scss";

.pagination-secondary {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    width: 3rem;
    height: 3rem;
    border: 1px solid $color-primary-light;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;

    p {
      color: $color-primary-light;
      transition: all 0.3s;
    }

    &:hover {
      border: 1px solid $color-secondary;
      p {
        color: $color-secondary;
      }
    }
  }

  .active {
    &,
    &:focus,
    &:active {
      border: 1px solid $color-secondary;
      background-color: $color-secondary;

      p {
        color: $white !important;
      }
    }
  }
}
