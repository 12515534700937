@import "../../../ui//main.scss";

.case-studies-container {
  width: 100%;
  .img-container {
    position: relative;
    .bg-dark {
      background-color: rgba($black, 0.75) !important;
      position: absolute;
      inset: 0;
    }
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }

  .case-studies {
    width: 100%;
    h1 {
      display: block;
    }

    padding: 3rem 10rem;

    .lead {
      font-size: 2.5rem;
      color: $color-primary-light;
    }
    p {
      transition: all 0.2s;
    }
    .card-tertiary {
      &:hover {
        p {
          color: $dark-grey;
        }
      }
    }
    img {
      width: 30rem;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .case-studies-container {
    .case-studies {
      padding: 3rem 5rem;
      .card-tertiary {
        margin-bottom: 2rem;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .case-studies-container {
    .case-studies {
      padding: 3rem 3rem;
    }
    .img-container {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
