@import "../../ui/main.scss";

.credit-card-container {
  position: relative;
  width: 100%;
  max-width: 32rem;
  height: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform-style: preserve-3d;

  &:hover {
    .credit-card {
      .front {
        transform: perspective(500px) rotateY(180deg);
      }
      .back {
        transform: perspective(500px) rotateY(360deg);
      }
    }
  }
}

.credit-card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-style: preserve-3d;
  color: $white;
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $dark-grey;
  transition: all 1s;
  border-radius: 10px;
  overflow: hidden;
  transform: rotateY(0deg);
}

.front {
  padding: 2rem;
}

.back {
  transform: rotateY(180deg);
}

.front,
.back {
  img {
    width: 50px;
  }
  h6 {
    color: $white;
    font-size: 2.3rem;
    margin-bottom: 0;
  }
  p {
    font-size: 1.2rem;
    color: $white;
  }
  span {
    font-size: 1rem;
    color: $white;
  }
}

img {
  display: block;
}

.black-stripe {
  margin-top: 2rem;
  width: 100%;
  height: 3rem;
  background-color: $black;
  color: $white;
}

.white-stripe {
  margin-top: 4rem;
  width: 85%;
  height: 3rem;
  background-color: $white;
  padding: 0 2rem;

  p {
    color: $black;
    font-size: 1.6rem;
  }
}
.credit-card-container-rotate {
  .credit-card {
    .front {
      transform: perspective(500px) rotateY(180deg);
    }
    .back {
      transform: perspective(500px) rotateY(360deg);
    }
  }

  &:hover {
    .credit-card {
      .front {
        transform: perspective(500px) rotateY(0deg);
      }
      .back {
        transform: perspective(500px) rotateY(180deg);
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .front,
  .back {
    h6 {
      font-size: 2rem;
    }
  }
}
