.blog-description {
  width: 100%;
  padding: 3rem 10rem;
}

@media screen and (max-width: 600px) {
  .blog-description {
    padding: 3rem 3rem;
  }
}
