@import "../../ui//main.scss";

.applications {
  // .container {
  //   margin-right: 0 !important;
  //   margin-left: 0 !important;
  //   width: 100% !important;
  // }
  &-content {
    padding: 3rem 10rem;
    width: 100%;
    overflow: hidden;
  }
  img {
    border-radius: 5px;
  }
  .lead {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1100px) {
  .applications {
    &-content {
      padding: 3rem 5rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .applications {
    &-content {
      padding: 3rem 3rem;
    }
  }
}
