@import "../main.scss";

.card-primary {
  padding: 8px;
  width: 100%;
  // margin-bottom: 2rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: $white;
  box-shadow: 5px 5px 17px 0px rgba(45, 42, 38, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  h5 {
    display: block !important;
  }
  p {
    display: block !important;
  }

  .card-img-left {
    max-width: 250px;
    min-width: 250px;
    height: 150px;
    object-fit: contain;
  }

  .card-body {
    padding: 2rem;

    .amount,
    .qty {
      padding: 0.2rem 1rem;
      background-color: $light-grey;
      border-radius: 5px;
      color: $white;
    }
  }

  h5 {
    display: inline-block;
    color: $color-primary-light;
    text-decoration: none;
    position: relative;

    // &::after {
    //   content: "";
    //   height: 1px;
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    //   background-color: transparent;
    //   display: block;
    //   transition: all 0.2s;
    // }

    &:hover {
      text-decoration: underline;
    }
  }

  .btn-primary {
    margin-left: 2rem;
    min-width: fit-content;
  }

  .in-stock-card {
    background-color: rgba(green, 0.1);
    padding: 0.3rem 0.75rem;
    border-radius: 5px;

    color: green;
  }
}

.price {
  font-size: 1.6rem;
  margin-bottom: 0;
  line-height: 1.6;
  span {
    font-size: 2rem;
    margin-left: 0.5rem;
  }
}

@media only screen and (max-width: 960px) {
  .card-primary {
    display: flex;
    flex-direction: column;
    .btn-primary {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 760px) {
  .card-primary {
    flex-direction: column;
    .btn-primary {
      margin-left: 0;
      margin-top: 2rem;
    }
  }

  .card-img-left {
    max-width: 500px !important;
    min-width: unset;
  }
}
