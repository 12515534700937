.sku-history {
  padding: 3rem 10rem;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
  .out-stock {
    color: red;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1100px) {
  .sku-history {
    padding: 3rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .sku-history {
    padding: 3rem 3rem;
  }
}
