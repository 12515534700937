@import "../../../ui/main.scss";

.account-info {
  padding: 3rem 10rem;
  width: 100%;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   grid-template-areas: "billing-information shippiing-information";

  .faq-form input {
    width: 100%;
  }

  label {
    font-weight: bold;
    .red {
      color: red;
      font-weight: 100;
      font-size: 1.2rem;
    }
  }

  .billing-information {
    //   grid-area: billing-information;
    padding: 0 3rem;
    // text-align: left;
  }

  .shippiing-information {
    //   grid-area: shippiing-information;
    padding: 0 3rem;
    // text-align: left;
    // .background-blue {
    //   background-color: rgba($color-primary, 0.1);
    // }
  }
}

@media only screen and (max-width: 1100px) {
  .account-info {
    padding: 3rem 5rem;
  }
}

@media only screen and (max-width: 900px) {
  .account-info {
    padding: 3rem 5rem;
  }
}

@media only screen and (max-width: 640px) {
  .account-info {
    padding: 3rem 3rem;

    .billing-information {
      //   grid-area: billing-information;
      padding: 0 2rem;
    }

    .shippiing-information {
      //   grid-area: shippiing-information;
      padding: 0 2rem;
    }
  }
}
