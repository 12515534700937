@import "../../ui//main.scss";

.product-container {
  .items {
    display: flex;

  }
}

.product-info {
  .display-4 {
    margin-bottom: 2rem !important;
    font-size: 3rem;
    &::before {
      height: 3px !important;
      bottom: -12%;
      width: 15%;
    }
  }
}

.product-display {
  padding: 5rem 3rem;
  // padding-left: 300px !important;
  width: 100%;
  margin: 0 auto;

  .product-image {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba($black, 0.2);
    // margin-bottom: 3rem;

    max-width: 50rem;
    // min-width: 30rem;
    height: 30rem;
    cursor: zoom-in;

    div {
      width: 100% !important;
      height: 100% !important;
      img {
        max-width: 100% !important;
        height: 100% !important;
      }
    }
    transition: all 0.2s;

    // &:hover {
    //   transform: scale(1.1);
    //   box-shadow: 5px 5px 5px 5px rgba($black, 0.1);
    // }
  }
  .product-image-small {
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba($black, 0.2);
    width: 75px;
    height: 50px;
    margin: 0 1rem;
    cursor: pointer;
    opacity: 0.75;
    backface-visibility: hidden;
    transition: all 0.2s;
    border: 2px solid transparent;

    &:hover {
      opacity: 1;
      transform: perspective(1px) scale(1.1);
      border: 2px solid $color-secondary;
      box-shadow: 5px 5px 5px 3px rgba($black, 0.1);
    }
  }

  .img-active {
    opacity: 1;
    transform: perspective(1px) scale(1.1);
    border: 2px solid $color-secondary;
    box-shadow: 5px 5px 5px 3px rgba($black, 0.1);
  }

  .product-info {
    margin-left: 2rem;
    font-size: 1rem;
    max-width: 50rem;
    .lead {
      font-size: 1.5rem;
    }
    .data-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .mpn,
    .upc {
      padding: 0.2rem 1rem;
      background-color: rgba($color-primary, 0.05);
      border-radius: 5px;
      height: fit-content;
    }
  }

  .price {
    font-size: 1.5rem;
    margin-bottom: 0;
    line-height: 1.6;
    display: flex;
    align-items: center;
    background-color: rgba($color-primary, 0.05);
    padding: 0.3rem 0.75rem;
    border-radius: 5px;
    p {
      margin-right: 1rem;
    }
    span {
      font-size: 2rem;
      margin-left: 0.5rem;
    }
  }

  .fourk-logo {
    width: 10rem;
  }

  .warrantyLogo {
    width: 7.5rem;
    margin-left: 4rem;
  }
}

.inquire-product {
  padding: 3rem 10rem;
  width: 100%;

  select {
    border: 1px solid rgba($color-primary, 0.05);
    padding-left: 1rem;
    &:focus {
      border: 1px solid $color-secondary !important;
    }
  }

  textarea {
    padding: 1rem;
    &::placeholder {
      color: rgba($light-grey, 0.75);
    }
  }

  .inquiry-form {
    margin: 0 10rem;
  }
}

// .img-magnifier-glass {
//   position: absolute;
//   border: 3px solid #000;
//   border-radius: 50%;
//   cursor: none;
//   /*Set the size of the magnifier glass:*/
//   width: 150px;
//   height: 150px;
// }

.in-stock {
  background-color: rgba(green, 0.1);
  padding: 0.3rem 0.75rem;
  border-radius: 5px;
  p {
    color: green;
    span {
      color: green;
      font-size: 2rem;
    }
  }
}

.out-of-stock {
  background-color: rgba(red, 0.1);
  padding: 0.3rem 0.75rem;
  border-radius: 5px;
  p {
    color: red;
  }
}

@media only screen and (max-width: 1100px) {
  .inquire-product {
    padding: 3rem 5rem;
    .inquiry-form {
      margin: 0 10rem;
    }
  }
}

@media only screen and (max-width: 640px) {
  .product-display {
    padding: 5rem 1rem;
    .product-image {
      max-width: 50rem;
      height: 20rem;
      .js-image-zoom__zoomed-area,
      .js-image-zoom__zoomed-image {
        display: none !important;
      }
    }
  }
  .product-info {
    .mpn,
    .upc {
      margin-bottom: 2rem;
    }
  }

  .inquire-product {
    padding: 3rem 3rem;

    .inquiry-form {
      margin: 0 2rem;
    }
  }

  .in-stock {
    font-size: 2rem;
    color: green;
  }

  .out-of-stock {
    font-size: 2rem;
    color: red;
  }
}

#img-container {
  z-index: 1;
  width: fit-content;
  position: relative;
}

#lens {
  z-index: 2;
  position: absolute;
  height: 150px;
  width: 150px;
  border: 2px solid black;
  background-repeat: no-repeat;
  cursor: none;
}

.hide-lens {
  opacity: 0;
}
#img-container .lens {
  opacity: 0;
  display: none;
}
#img-container:hover .lens {
  opacity: 1;
  display: inline-block;
}
