.terms {
  width: 100%;
  padding: 3rem 10rem;

  .info {
    .section {
      margin-bottom: 3rem;

      .display-5 {
        margin-bottom: 3rem !important;
      }
      p {
        margin-bottom: 2rem;
      }
    }
  }
}

.terms-banner {
  margin-bottom: 2rem;
}

@media screen and (max-width: 600px) {
  .terms {
    padding: 3rem 3rem;
  }
}
